import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Stack,  List, ListItem, Grid } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import WorkIcon from '@mui/icons-material/Work';

export const Job=({job})=> {

  return (
    <Card
      sx={{
        display: { xs: "flex-column", md: "flex" } ,
        width: "100%",
        backgroundColor: "#457B9D",
      }}
    >

        <CardContent >
          <Grid container 
            spacing={1} 
            justifyContent="space-between"
            sx={{
              flexDirection:{xs:"column", sm:"column", md:"row"}
            }}
          >
            <Grid item>
               <WorkIcon sx={{margin:1, fontSize: 50, color:"#F1FAEE"}}/>
            </Grid>
            <Grid item>
              <Typography 
                component="div"
                gutterBottom 
                color="#A8DADC"
                sx={{ 
                  letterSpacing:{ xs:2, md:4 },
                  fontSize:{ xs:15, md:18 },
                  
                }} 
              >
                  {job.period}
              </Typography>
              <Typography 
                component="div"
                gutterBottom 
                
                color="#F1FAEE"
                sx={{ 
                  letterSpacing:{ xs:3, md:4 },
                  fontSize:{ xs:13, md:15 }
                }} 
              >
                  <b>{job.company}</b> ({job.location})
              </Typography>
            </Grid>
          </Grid>
        
            <Typography 
                component="div"
                gutterBottom 
                color="#64ffda"
                mt={1}
                sx={{ 
                  letterSpacing:{ xs:4, md:6 },
                  fontSize:{ xs:20, md:25 },
                  fontStyle: 'bold'
                }}
            >
            {job.title}
            </Typography>
      
          
           
            <List component={Stack} 
                direction="column" 
                spacing={1}
            >
                {job.description.map((item)=>(
                    <ListItem 
                        key={item}
                        sx={{ color:"#F1FAEE"}}
                    >
                      <ArrowForwardIosIcon sx={{fontSize:15, color:"#64ffda", marginRight:2}}/>
                      <Typography 
                          component="div"
                          gutterBottom 
                          color="#F1FAEE"
                          sx={{ 
                          letterSpacing:{ xs:2, md:4 },
                          fontSize:{ xs:15, md:18 }
                      }} >
                          {item}
                      </Typography>
           
                    </ListItem>
                ))}  
           </List>
        </CardContent>

    </Card>
  );
}
