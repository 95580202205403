const IMAGES = {
    type1: require("./memoryGame.JPG"),
    type2: require("./GithubUsers.JPG"),
    type3: require("./TodoApp.JPG"),
    type4: require("./mobile.JPG"),
    type5: require("./cssExample.JPG"),
    type6: require("./PersonalPage.JPG"),
    type7: require("./githubRepo.JPG"),
  };
  export default IMAGES;
  