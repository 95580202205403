import React, { useState } from "react";
import DiamondIcon from '@mui/icons-material/Diamond';
import { AppBar, Button, Toolbar, Link, Container, IconButton, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { List, ListItem } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { HashLink as LinkHash } from 'react-router-hash-link';

export const Header=()=>{
    const [showDrawer, setShowDrawer]=useState(false);
    const navigationLinks=[
        {name:"About",  href:"#about"},
        {name:"Projects",  href: "#projects"},
        {name:"Experience", href: "#experience"},
        {name:"Contact", href: "#contact"},

    ];

    return (
        <AppBar position="sticky" style={{ background: '#457B9D', height:"10vh" }} >
            <Container maxWidth="lg" >
                <Toolbar disableGutters 
                    sx={{  justifyContent: "center",
                            alignItems: "center",
                            height:"10vh"}} >
                    <LinkHash to="#intro" style={{marginRight:"auto"}}>
                        <DiamondIcon sx={{marginRight:"auto", color:"#64ffda", fontSize: 40  }} />
                    </LinkHash>
                    
                    
                    {navigationLinks.map((item)=>(
                         <LinkHash to={item.href} style={{textDecoration:"none"}} key={item.name}>
                            <Link 
                                component="li"
                                underline="none" 
                                color="#F1FAEE" 
                                variant="button" 
                                ml={2} 
                                sx={{display:{xs:"none", sm:"none",  md:"block" }, fontSize: 18}}
                                >
                                    {item.name}
                            </Link>
                        </LinkHash>
          
                    ))}
                    <Button  
                        href="ResumeVoronovaA.pdf" 
                        download
                        sx={{
                            display:{xs:"none",sm:"none",  md:"block" }, 
                            color:"#1D3557", 
                            background:"#A8DADC",
                            marginLeft:"20px",
                            fontSize:"18px"
                        }} 
                        variant="contained"
                    >
                        Download resume
                    </Button>
                    <IconButton sx={{display:{  md:"none" }}} onClick={()=>setShowDrawer(true)}>
                        <MenuIcon  sx={{color:"#F1FAEE", fontSize:40}}/>
                    </IconButton>
                </Toolbar>
            </Container>
            <SwipeableDrawer 
                open={showDrawer} 
                anchor="right" 
                onClose={()=>setShowDrawer(false)} 
                onOpen={()=>setShowDrawer(true)} 
                PaperProps={{
                    sx: {
                    backgroundColor: "F1FAEE",
                    color: "#1D3557",
                    width:"60%"
                
                    }
                }}>
                <Container sx={{ height:{ xs:"10vh", sm:"10vh"}}}>
                    <IconButton sx={{marginTop:"15px"}} onClick={()=>setShowDrawer(false)} >
                        <ChevronRightIcon sx={{color:"#1D3557", fontSize:40}}/>
                    </IconButton>
                </Container>
                
                <Divider/>
                <List>
                    {navigationLinks.map((item)=>(
                        <ListItem key={item.name}>
                             <LinkHash to={item.href} style={{textDecoration:"none"}}>
                                <Typography variant="button"
                                    sx={{
                                        underline:"hover",
                                        color:"#1D3557",
                                        fontSize:18
                                    }}
                                >
                                {item.name}
                                </Typography>
                             </LinkHash>
                            
                        </ListItem>    
                    ))}
                    <ListItem >
                    <Button  
                        href="ResumeVoronovaA.pdf" 
                        download
                        sx={{color:"#457B9D",    fontSize:"18px"}}
                    >
                        Download resume
                        <DownloadIcon sx={{marginLeft:"10px"}}/>
                    </Button>
                    </ListItem>
                </List>
                
            </SwipeableDrawer>
       </AppBar>
    );
}