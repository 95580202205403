import React from 'react';
import { Header } from '../Header';
import { Box, Container } from "@mui/material";
import { AboutMe } from '../AboutMe';
import { Projects } from '../Projects';
import { Experience } from '../Experience';
import { Contacts } from '../Contacts';
import { Intro } from '../Intro';
import { Footer } from '../Footer';

export const Home =()=>{
  return (
    <Box
      position="fixed"
      top={0}
      height="100vh"
      width="100vw"
      sx={{backgroundColor:"#1D3557",  overflowY: "scroll"}}
    >
      <Header/>
      <Container maxWidth="md">
          <div id="intro">
            <Intro/>
          </div>
          <div id="about">
            <AboutMe />
          </div>
          <div id="projects">
            <Projects />
          </div>
          <div id="experience">
          <Experience  />
          </div>         
          <div id="contact">
            <Contacts />
          </div>
          
      </Container>
      <Footer/>
    </Box>

  )
}