import React from "react";
import { Stack,  Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

export const Contacts=()=>{
    return(
        <>
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={4}
            sx={{
                minHeight:"80vh",
            }}
        >
            <Typography  component="div" gutterBottom color="#64ffda" 
                sx={{ 
                letterSpacing:{ xs:4, md:6 },
                fontSize:{ xs:20, md:40 }
            }} >
                04. Reach out!
            </Typography>

            <Divider 
                style={{width:'100%'}}
                sx={{color:"#F1FAEE", borderTop: "2px solid #F1FAEE" }} 
            />
          
            <Typography 
            
                variant="h4" 
                component="div"
                gutterBottom 
                color="#F1FAEE"
                sx={{ 
                letterSpacing:{ xs:4, md:6 },
                fontSize:{ xs:15, md:23 }
                }} 
            >
                I'm currently available for new opportunities.
              
            </Typography>
         
            <Typography variant="h4" component="div" gutterBottom color="#A8DADC"
                mb={3}
                sx={{ 
                letterSpacing:{ xs:4, md:6 },
                fontSize:{ xs:15, md:25 }
                }} >
                Let's connect!
            </Typography>
            <Button  
                href="mailto:webdev.aleksandra@gmail.com"
                sx={{
                    color:"#1D3557", 
                    background:"#A8DADC",
                    fontSize:{xs:"13px", sm: "15px", md:"20px"},
                }} 
               
                variant="contained"
            >
                Send me a message
            </Button>
      </Stack>
        </>
    )
}