import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Stack,  List, ListItem } from "@mui/material";
import GitHubIcon from '@mui/icons-material/GitHub';
import LaunchIcon from '@mui/icons-material/Launch';

export const CardComponent=({project})=> {

  return (
    <Card
      sx={{
        display: { xs: "flex-column", md: "flex" } ,
        width: "100%",
        backgroundColor: "#457B9D",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#457B9D",
        }}
      >
        <CardMedia
          component="img"
          sx={{
              display:{xs:"none", sm:"none", md:"block"},
            opacity: 0.3,
            width:"400px", 
            height:"300px",
            objectFit: "contain"
          }}
          image={project.pic}
          alt={project.name}
        />
      </Box>

      <Box 
        sx={{
         display: "flex", 
         flexDirection: "column",
         width: "auto",
        }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography 
                component="div"
                gutterBottom 
                color="#64ffda"
                sx={{ 
                letterSpacing:{ xs:4, md:6 },
                fontSize:{ xs:18, md:20 }
            }} >
            {project.name}
            </Typography>
            <Typography 
                component="div"
                gutterBottom 
                color="#F1FAEE"
                sx={{ 
                letterSpacing:{ xs:2, md:4 },
                fontSize:{ xs:13, md:15 }
            }} >
                {project.description}
            </Typography>
           
            <List component={Stack} 
                direction="row" 
                spacing={1}
                sx={{display:{xs:"none", md:"flex"}}}
            >
                {project.stack.map((item)=>(
                    <ListItem 
                        key={item}
                        sx={{backgroundColor:"#A8DADC", color:"#1D3557", borderRadius:"8px", width:"auto"}}
                    >
                        {item}
                    </ListItem>
                ))}  
           </List>
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
            <IconButton href={project.repo} target="_blank">
                <GitHubIcon sx={{color:"#64ffda", fontSize: 30}}/>    
            </IconButton>
            <IconButton  href={project.web} target="_blank">
                <LaunchIcon sx={{color:"#64ffda", fontSize: 30}}/>
            </IconButton>
        </Box>
      </Box>
    </Card>
  );
}
