import React from "react";
import { Stack} from "@mui/material";
import Typography from '@mui/material/Typography';


export const Intro=()=>{
   
    return(
        <>
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
            height:"90vh",
            }}
            spacing={2}
        >
            <Typography variant="h4" component="div" gutterBottom color="#A8DADC" 
                sx={{ 
                letterSpacing:{ xs:4, md:6 },
                fontSize:{ xs:20, md:40 }
                }} >
                Hi, my name is
            </Typography>
            <Typography  component="div" gutterBottom color="#64ffda"  
                sx={{ 
                letterSpacing:{ xs:4, md:6 },
                fontSize:{ xs:45, md:60 }
                }}  >
                Aleksandra Voronova
            </Typography>
            {/* <Typography variant="h4" component="div" gutterBottom color="#A8DADC"
            sx={{ 
                letterSpacing:{ xs:4, md:6 },
                fontSize:{ xs:20, md:40 }
                }} >
            I create things for the web.
            </Typography> */}
            <Typography variant="h4" component="div" gutterBottom color="#F1FAEE"
                sx={{ 
                letterSpacing:{ xs:4, md:6 },
                fontSize:{ xs:20, md:40 }
                }} >
            I’m a frontend developer specializing in building great digital products.
            </Typography>
      </Stack>
        </>
    )
}