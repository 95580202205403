import React from "react";
import { Stack } from "@mui/material";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IMAGES from  '../assets/index';
import { CardComponent } from "./Card/Card";


export const Projects=()=>{
    const projects=[
        {name:"Top GITHUB Repos", id:7, description:"Shows most popular GitHub repositories, dark/light mode. Responsive website, displays different design on pc, tablet and phone, swipeable. Has Skeleton for better user experience", stack:["React", "Redux", "CSS"], repo:"https://github.com/hello-Sasha/TopJSReposGitHub", web:"https://hello-sasha.github.io/TopJSReposGitHub", pic:IMAGES.type7},
        {name:"Todo App", id:3, description:"This App is not just simple todo app. Anyone can register and login thanks to implementation of Firebase Authentication. Each todo can be edited/deleted and updates right away. Each user sees only his own data.  App is Mobile friendly.", stack:["React", "MUI", "Firebase", "React Router"], repo:"https://github.com/hello-Sasha/todoFireBaseApp", web:"https://hello-sasha.github.io/todoFireBaseApp/#/login", pic:IMAGES.type3},
        {name:"My Personal Page", id:6, description:"Personal Resume-page, mobile friendly. Developed with React and MUI. Shows list of my projects with links to it and it's repos, contact information and resume download. Menu is swipeable.", stack:["React", "MUI"], repo:"https://github.com/hello-Sasha/", web:"/", pic:IMAGES.type6},
        {name:"Memory Game", id:1, description:"Simple Memory game. You need to find matching pairs of cards in 40 moves or less. Mobile friendly.", stack:["React", "CSS"], repo:"https://github.com/hello-Sasha/memorygamegh", web:"https://hello-sasha.github.io/memorygamegh/", pic:IMAGES.type1},
        {name:"GH Users List", id:2, description:"App uses GitHub API to get list of users. Each user has his own card with all vital information and lists of repos and links to it. Another great feature - user search by nickname. Routes are dynamic. App is Mobile friendly.", stack:["React", "MUI", "TS", "React Router"], repo:"https://github.com/hello-Sasha/gitHubMui", web:"https://hello-sasha.github.io/gitHubMui/", pic:IMAGES.type2},
        {name:"Responsive Mobile", id:4, description:"Example of responsive website, that display different background and menu on pc, tablet and phone.", stack:["React", "CSS"], repo:"https://github.com/hello-Sasha/mobile/", web:"https://hello-sasha.github.io/mobile", pic:IMAGES.type4},
        {name:"Css Landing Page", id:5, description:"Example of Landing Page. All made with only CSS. Desktop version.", stack:["HTML", "CSS"], repo:"https://github.com/hello-Sasha/cssExample", web:"https://hello-sasha.github.io/cssExample/", pic:IMAGES.type5},
       
    ];
   
    return(
        <>
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{
                minHeight:"90vh",
            }}
            mt={4}
        >
            <Typography  component="div" gutterBottom color="#64ffda" 
                sx={{ 
                letterSpacing:{ xs:4, md:6 },
                fontSize:{ xs:20, md:40 }
            }} >
                02. Projects
            </Typography>

            <Divider 
                style={{width:'100%'}}
                sx={{color:"#F1FAEE", borderTop: "2px solid #F1FAEE" }} 
            />
            
            {projects.map((project)=>(
                 <CardComponent project={project} key={project.id}/>
            ))}
           
      </Stack>
        </>
    )
}