import React from "react";
import { AppBar, Box,Stack } from "@mui/material";
import Typography from '@mui/material/Typography';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CodeIcon from '@mui/icons-material/Code';
import IconButton from '@mui/material/IconButton';


export const Footer=()=>{
   
    return (
        <AppBar 
            position="static" 
            style={{ 
                background: '#1D3557', 
                minHeight:"10vh",
                bottom: 0 
                }} 
        >
            <Stack 
                justifyContent="center"
                alignItems="center"
            >
                <Box >
                <IconButton sx={{color:"#F1FAEE"}} href={"https://github.com/hello-Sasha"} target="_blank">
                    <GitHubIcon/>
                </IconButton>
                <IconButton sx={{color:"#F1FAEE"}} href={"https://www.linkedin.com/in/aleksandra-voronova-90038878/"} target="_blank">
                    <LinkedInIcon/>
                </IconButton>
                <IconButton sx={{color:"#F1FAEE"}}  href={"https://codesandbox.io/u/hello-Sasha"} target="_blank">
                    <CodeIcon/>
                </IconButton>
                   
                </Box>
                <Typography 
                    component="div" 
                    gutterBottom color="#64ffda"
                    align="center"
                    sx={{ 
                    letterSpacing:{ xs:4, md:6 },
                    fontSize:{ xs:10, md:18 }
                    }} >
                    Designed & Created by Aleksandra Voronova
                </Typography>
            </Stack>
       </AppBar>
    );
}