import React from "react";
import { Stack} from "@mui/material";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { Job } from "./Card/Job";


export const Experience=()=>{
    const experience=[
        {period:"December 2021 - now", id:0, title:"Frontend Web Developer", company:"Karpov School Bootcamp", location: "remote",  
            description:[
                "Create responsive web pages with React, TS, MUI",
                "Implementing backend library - FireBase",
                "Troubleshoot, test, and debug code",
                "Redux",
                "Maintain and improve existing web features: dark theme, skeleton, simple animation",
            ]},
        {period:"August 2019 - June 2021", id:1, title:"Frontend Web Developer", company:"CSS IMPACT", location: "Los Angeles, USA",  
            description:[
                "Responsible for building web pages for consumers  with reusable components",
                "Optimized web pages and ensuring capability with desktops, tablets, and mobile devices",
                "Troubleshoot, test, and debug code",
                "Collaborate with project manager and web development team to improve efficiency",
                "Maintain and improve existing web features",
                "Maintain a clean, presentable codebase with attention to detail and consistency"
            ]},
        {period:"April 2013 - July 2017", id:2, title:"Head of Market Analysis and Pricing Department", company:"Absolute Group LLC", location: "Moscow, Russia",  
            description:[
                "Building and maintaining sales reporting system",
                "Marketing research of residential and commercial real estate",
                "Pricing and promotion marketing",
                "Develop and maintain internal database",
                "Sales and inventory forecast"
            ]},
        {period:"Sep 2009 - March 2013", id:3, title:"Data analyst", company:"EASTEX GROUP", location: "Moscow, Russia",  
            description:[
                "Develop and implement software module",
                "Writing the technical specification",
                "Analysis of the external and internal environment of the company",
                "Marketing research (clients, consumers, financial analysis)"
            ]}
    ];
    
    return(
        <>
        <Stack
            direction="column"
            mt={20}
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{
                minHeight:"90vh",
            }}
        >
            <Typography  component="div" gutterBottom color="#64ffda" 
                sx={{ 
                letterSpacing:{ xs:4, md:6 },
                fontSize:{ xs:20, md:40 }
            }} >
                03. Experience
            </Typography>

            <Divider 
                style={{width:'100%'}}
                sx={{color:"#F1FAEE", borderTop: "2px solid #F1FAEE" }} 
            />

            {experience.map((job)=>(
                 <Job job={job} key={job.id}/>
            ))}

      </Stack>
        </>
    )
}