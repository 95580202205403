import React from "react";
import {Routes, Route} from 'react-router-dom';
import {Home} from './components/Home/Home';

import { Typography } from "@mui/material";


export const RoutesResume=()=>{

  return (
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="*"  element={
        <Typography variant="h4" gutterBottom component="div" align="center" mt={1} color="#1b5e20">
          Page not found
        </Typography>
      }/>
    </Routes>
  )

}