import React from "react";
import { Stack, Grid, ListItem, List } from "@mui/material";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DoneIcon from '@mui/icons-material/Done';

export const AboutMe=()=>{
    const textArray=[
        "Hello! I'm Aleksandra and I enjoy creating websites. Before being a web developer, I was a data analyst.", 
        "I was introduced to the world of web development in 2019 where I interned as a part time web developer.",
        "During that time I learned basics for CSS, HTML and JavaScript. Also  I became knowledgeable on frameworks and libraries such as Vue, Bootstrap, and Bulma.",
    ];
    const stack=[
        "JavaScript (ES6+)", "React", "MUI", "Bulma", "TypeScript", "Firebase"
    ]
    return(
        <>
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{
                minHeight:"90vh",
            }}
        >
            <Typography  component="div" gutterBottom color="#64ffda" 
                sx={{ 
                letterSpacing:{ xs:4, md:6 },
                fontSize:{ xs:20, md:40 }
            }} >
                01. About
            </Typography>

            <Divider 
                style={{width:'100%'}}
                sx={{color:"#F1FAEE", borderTop: "2px solid #F1FAEE" }} 
            />
            {textArray.map((par)=>(
                 <Typography 
                    key={par}
                    variant="h4" 
                    component="div"
                    gutterBottom 
                    color="#F1FAEE"
                    sx={{ 
                    letterSpacing:{ xs:4, md:6 },
                    fontSize:{ xs:15, md:23 }
                    }} >
                        {par}
                </Typography>
            ))}
            <Typography variant="h4" component="div" gutterBottom color="#A8DADC"
                sx={{ 
                letterSpacing:{ xs:4, md:6 },
                fontSize:{ xs:15, md:25 }
                }} >
                Here are a few technologies I’ve been working with recently:
            </Typography>
            <Grid container spacing={1}>
                {stack.map((item)=>(
                    <Grid item  xs={6} key={item}>
                        <List dense={true}>
                            <ListItem>
                                <Typography  component="div" gutterBottom color="#64ffda" 
                                    sx={{ 
                                    letterSpacing:{ xs:2, sm:4, md:6 },
                                    fontSize:{ xs:13, md:20 }
                                }} >
                                    <DoneIcon sx={{fontSize:{ xs:13, md:20 }, marginRight:{ xs:1, sm:2, md:3 }}} />
                                    {item}
                                </Typography>
                            </ListItem>
                        </List>
                    </Grid>
                ))}
            </Grid>
      </Stack>
        </>
    )
}